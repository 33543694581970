import { Google, GoogleRound } from "assets";
import Button from "components/Button";
import Section from "components/Section";
import { NextPage } from "next";
import classes from "styles/pages/Login.module.scss";
import FakeFooter from "components/FakeFooter";
import {
  loginWithEmail,
  loginWithGoogle,
  resetLink,
  resetPassword,
} from "config/firebase";
import { FormEventHandler, useEffect, useState } from "react";
import { useUser } from "contexts/user";
import { useRouter } from "next/router";
import { toast } from "react-toastify";

interface Props {}

const Auth: NextPage<Props> = () => {
  const {
    push,
    query: { email, oobCode, redirect = `"/dashboard"` },
    ...router
  } = useRouter();
  const { user } = useUser();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    retypePassword: "",
  });
  const pathname = router.pathname as
    | "/login"
    | "/forgot_password"
    | "/reset_password";

  useEffect(() => {
    if (user && pathname === "/login") push(JSON.parse(redirect.toString()));
  }, [user, pathname, push, redirect]);
  useEffect(() => {
    if (email) setFormData((p) => ({ ...p, email: email.toString() }));
  }, [email, setFormData]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    switch (pathname) {
      case "/login":
        if (!formData.email || !formData.password)
          return toast.error("Please enter Email and password");
        loginWithEmail(formData);
        break;
      case "/forgot_password":
        resetLink(formData.email);
        break;
      case "/reset_password":
        if (
          !/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/.test(
            formData.password
          )
        )
          return toast.error(
            "Password must contain at least 8 characters, one uppercase, one number and one special case character"
          );
        if (formData.password !== formData.retypePassword)
          return toast.error("Passwords do not match");
        resetPassword(oobCode.toString(), formData.password, () =>
          push("/login")
        );
    }
  };

  return (
    <>
      <Section
        className={classes.container}
        innerClassName={classes.innerContainer}
      >
        <div className={classes.left}>
          <div className={classes.text}>
            <h2>Incorporate your Business with Confidence</h2>
            <p>
              Easy, Smart, Affordable and Fast.
              <br />
              Get your Business started with IncDecentral.
            </p>
          </div>
        </div>
        <div className={classes.right}>
          <div className={classes.form}>
            <form onSubmit={handleSubmit}>
              {pathname === "/reset_password" && (
                <div className={classes.head}>
                  <h3>Create new password</h3>
                  <h4>
                    We’ll ask for this password whenever you sign in again
                  </h4>
                </div>
              )}
              {["/login", "/forgot_password"].includes(pathname) && (
                <input
                  type="text"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData((p) => ({ ...p, email: e.target.value }))
                  }
                  placeholder="Email"
                />
              )}
              {["/login", "/reset_password"].includes(pathname) && (
                <input
                  type="password"
                  value={formData.password}
                  onChange={(e) =>
                    setFormData((p) => ({ ...p, password: e.target.value }))
                  }
                  placeholder="Password"
                />
              )}
              {["/reset_password"].includes(pathname) && (
                <input
                  type="password"
                  value={formData.retypePassword}
                  onChange={(e) =>
                    setFormData((p) => ({
                      ...p,
                      retypePassword: e.target.value,
                    }))
                  }
                  placeholder="Retype Password"
                />
              )}
              {["/reset_password", "/login"].includes(pathname) && (
                <p
                  onClick={() =>
                    push({
                      pathname: "/forgot_password",
                      query:
                        pathname === "/login"
                          ? { email: formData.email }
                          : undefined,
                    })
                  }
                  className={classes.forgot}
                >
                  {pathname === "/login"
                    ? "Forgot Password?"
                    : "I have entered the wrong email"}
                </p>
              )}
              <Button type="submit" theme="secondary">
                {pathname === "/login"
                  ? "Login"
                  : pathname === "/reset_password"
                  ? "Update password"
                  : "Reset my password"}
              </Button>
            </form>
            <div className={classes.hr}>
              <div className={classes.line} />
              <p>or</p>
              <div className={classes.line} />
            </div>
            <div className={classes.social}>
              {/* <FacebookRound />
              <AppleRound />
              <div onClick={loginWithGoogle}>
                <GoogleRound />
              </div> */}
              <Button
                className={classes.google}
                color="#B3B3B3"
                variant="outlined"
                flex
                onClick={loginWithGoogle}
              >
                <Google />
                <p>Continue with Google</p>
              </Button>
            </div>
          </div>
        </div>
      </Section>
      <FakeFooter />
    </>
  );
};

export default Auth;
