import {
  AddressFooter,
  Instagram,
  Logo,
  PhoneFooter,
  Twitter,
  Wave,
  Linkedin,
  Youtube,
} from "assets";
import { CONTACT_NUMBER } from "config/constants";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import classes from "styles/components/FakeFooter.module.scss";
import Section from "./Section";

export default function FakeFooter() {
  const { pathname } = useRouter();
  return (
    <div className={classes.container}>
      <div className={classes.wave}>
        <Wave />
      </div>
      <Section className={classes.foot} innerClassName={classes.innerFoot}>
        <div className={classes.left}>
          <div className={classes.logo}>
            <Logo />
            <h6>IncDecentral</h6>
          </div>
          <p className={classes.tag}>
            {pathname === "/login" ? (
              <>
                Fastest way to form a company
                <br />
                in just 5 minutes
              </>
            ) : (
              <>
                Easy, Smart, Affordable and Fast.
                <br />
                Get your Business started with IncDecentral.
              </>
            )}
          </p>
        </div>
        <div className={classes.center}>
          <h4>Quick Links</h4>
          <Link href="/workflow">
            <p>Register your company</p>
          </Link>
          <Link href="/why_us">
            <p>Why IncDecentral?</p>
          </Link>
          <Link href="/contact">
            <p>Contact Us</p>
          </Link>
          <Link href="/privacy_policy">
            <p>Privacy policy</p>
          </Link>
        </div>
        <div className={classes.right}>
          <h4>Follow us on</h4>
          <div className={classes.socials}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/incdecentral"
            >
              <div className={classes.link}>
                <Instagram />
              </div>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/incdecentral"
            >
              <div className={classes.link}>
                <Twitter />
              </div>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UCsRwJRJPW4pE1h2_KnI6OnQ"
            >
              <div className={classes.link}>
                <Youtube />
              </div>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/incdecentral"
            >
              <div className={classes.link}>
                <Linkedin />
              </div>
            </a>
          </div>
          <div className={classes.details}>
            <div className={classes.detail}>
              <AddressFooter />
              <p>1580 W. El Camino Real, Mountain View, CA 94040</p>
            </div>
            <div className={classes.detail}>
              <PhoneFooter />
              <p>{CONTACT_NUMBER.US}</p>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
